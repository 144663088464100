/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

// @import 'node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css');
// @import 'node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import url('https://cdnjs.cloudflare.com/ajax/libs/icheck-bootstrap/3.0.1/icheck-bootstrap.min.css');

// admin-lte
// Bootstrap
// ---------------------------------------------------
// @import "node_modules/bootstrap/scss/functions";
@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css');
// @import "node_modules/admin-lte/src/scss/adminlte.scss";
// @import "node_modules/admin-lte/build/scss/bootstrap-variables";

// override admin-lte variables here
// $theme-colors: (
//   primary: $blue,
//   secondary: $gray-600,
//   success: $green,
//   info: $cyan,
//   warning: $yellow,
//   danger: $red,
//   light: $gray-100,
//   dark: $gray-800
// );

// admin-lte cont.
// @import "node_modules/bootstrap/scss/bootstrap";
// // Variables and Mixins
// // ---------------------------------------------------
// @import "node_modules/admin-lte/build/scss/variables";
// @import "node_modules/admin-lte/build/scss/variables-alt";
// @import "node_modules/admin-lte/build/scss/mixins";
// @import "node_modules/admin-lte/build/scss/parts/core";
// @import "node_modules/admin-lte/build/scss/parts/components";
// @import "node_modules/admin-lte/build/scss/parts/extra-components";
// @import "node_modules/admin-lte/build/scss/parts/pages";
// @import "node_modules/admin-lte/build/scss/parts/plugins";
// @import "node_modules/admin-lte/build/scss/parts/miscellaneous";

@import 'node_modules/ngx-toastr/toastr';

a, a:hover {
    text-decoration: none;
}

body {
    width: 100vw;
    height: 100vh;
}

::-webkit-scrollbar {
    // width: 0px;
    // background: transparent;
}

.user-menu > .nav-link {
    cursor: pointer;
}

.user-menu > .nav-link:after {
    content: none;
}

.user-menu > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
    border-bottom: 1px solid #495057;
    border-top: 1px solid #dee2e6;
    padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
    display: block;
    clear: both;
    content: '';
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-body a {
        background: #ffffff !important;
        color: #495057 !important;
    }
}

.user-menu > .dropdown-menu > .user-footer {
    background-color: #f8f9fa;
    padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
    display: block;
    clear: both;
    content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #6c757d;
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f8f9fa;
    }
}

.user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2.1rem;
    margin-right: 10px;
    margin-top: -2px;
    width: 2.1rem;
}

@media (min-width: 576px) {
    .user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-right: 0.4rem;
        margin-top: -8px;
    }
}

.user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.content {
    padding: 0 0.5rem;
}

.dataTables_wrapper {
    .dataTables_paginate .paginate_button {
        padding: 0px !important;
    }
    .dataTables_empty {
        display: none;
    }
    img {
        max-width: 100px;
        max-height: 100px;
    }
    table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:after {
        display: none;
    }
    table.dataTable>thead .sorting:before, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_desc_disabled:before {
        display: none;
    }
}

.content.col {
  flex: 0 0 40%;
  max-width: 40%;
}

.aux.col {
  flex: 0 0 60%;
  max-width: 60%;
}



.max-1240 {
  display: none;
}

@media (max-width: 1240px) {
  .max-1240 {
    display: block;
  }
}

@media (max-width: 576px) {

    .qlview, .ql-container, .related-plans-horiztal-holder {
        width: calc(100vw - 2rem);
    }

    .ql-editor {
        padding: 0px;
        padding-top: 2rem;
      }

      p {
        word-break: break-word;
      }
}



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h1, h2, h3, h4 {
  font-size: 1.5rem;
}
